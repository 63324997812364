export const qualitypolicy = {
  ru: {
    translation: {
      'ОАО «Беларускабель» с декабря 2000 года была внедрена и сертифицирована система': `ОАО «Беларускабель» с декабря 2000 года была внедрена и сертифицирована система
              качества разработки, производства и поставки кабельной продукции и потребительских
              товаров (шнуров армированных, вилок опрессованных, удлинителей) на базе международных
              стандартов серии ИСО 9000. <br />
              <br /> В период с 01.10.2004 по 01.10.2013 года организация была сертифицирована на соответствие
              требованиям международного стандарта ISO 9001 «Системы менеджмента качества. Требования» KEMA Quality
              B.V. номер сертификата № 2040221-QUA. В период с 02.10.2013 по 01.10.2022 система менеджмента была
              сертифицирована на соответствие требованиям международного стандарта ISO 9001 DEKRA Certification GmbH.
              Германия (сертификат № 320513050).
              <br />
              <br />
              В сентябре 2022 года (в связи с отказом DEKRA работать с российскими и белорусскими партнерами) организация
              успешно прошла сертификацию на соответствие требованиям международного стандарта ISO 9001:2015 «Системы менеджмента
              качества. Требования».  Сертификат на СМК №BY229591 с 26.09.2022 по 26.09.2025 Bureau Veritas Certification Holding
              SAS – UK Branch выдан органом по сертификации Иностранное общество с ограниченной ответственностью «Бюро Веритас Бел Лтд».
              <br />
              <br /> С 2017 года организация успешно прошла сертификацию на соответствие требованиям стандарта
              СТБ ISO 9001-2015 «Системы менеджмента качества. Требования» в Национальной системе подтверждения
              соответствия Республики Беларусь. Сертификат на СМК №BY/112 05.01. 012.02 00052 с 13.10.2023 по 12.10.2026.
              Выдан органом по сертификации систем менеджмента Республиканское унитарное предприятие «Калинковичский центр
              стандартизации, метрологии и сертификации» (РУП «Калинковичский ЦСМС»).`,
      'В организации действует сертифицированная система управления окружающей средой согласно требованиям СТБ ISO 14001-2017': `В организации действует сертифицированная система менеджмента окружающей среды
      производства кабелей, проводов и шнуров, а также вспомогательной деятельности, обеспечивающей
      бесперебойную работу основного производства(обработка неметаллических отходов и лома, деятельность
        автомобильного грузового транспорта и электротранспорта, техническое обслуживание и ремонт
        автомобилей, организация перевозок грузов, механическая обработка древесины и изготовление
        деревянной тары, монтаж, наладка, ремонт и техническое обслуживание машин и производственного
        оборудования, электрооборудования, водоснабжение и водоотведение организации) согласно
        требованиям СТБ ISO 14001-2017 «Системы управления окружающей средой. Требования».
        Получен сертификат № BY/112 05.10. 021.01 00004 (от 03.09.2021 до 02.09.2024).
        Выдан Органом по сертификации систем управления окружающей средой филиала
        БНТУ «Научно-исследовательский политехнический институт». <br />
              <br /> ОАО «Беларускабель» единственный в Республике Беларусь кабельный завод,
              предоставляющий услуги по технической приемке продукции. <br />
              <br />`,
      'На ОАО «Беларускабель» дислоцируется Техническая приемка №43 АО «РТ-Техприемка»': `На ОАО «Беларускабель» дислоцируется Техническая приемка №43 АО «РТ-Техприемка»
              специализированная организация Госкорпорации «Ростех» (ранее АО «Авиатехприемка») по
              оценке соответствия продукции в форме контроля качества и технической приемке,
              согласно Положению от 22.10.2007 «О порядке проведения контроля качества материалов и
              полуфабрикатов, используемых в производстве вооружения, военной, авиационной и
              специальной техники». <br />
              <br /> ОАО «Беларускабель» входит в состав организаций Ассоциации «Электрокабель»,
              подписавших Декларацию качества, принятую на общем собрании 16 апреля 2008.
              <br />
              <br />`,
      'В организации действует сертифицированная система управления охраной труда при производстве кабелей': `В организации действует сертифицированная система управления охраной труда при
              производстве кабелей, проводов, шнуров, товаров народного потребления согласно
              требованиям СТБ ISO 45001‑2020 «Система менеджмента здоровья и безопасности при
              профессиональной деятельности». <br />
              <br />
              Получен сертификат № BY/112 05.04.021.03 00017 (от 09.08.2021 до 08.08.2024).
              <br />
              <br />
              Выдан органом по сертификации продукции, услуг, систем управления «ПОЛИТЕХ-СЕРТ».`,
    },
  },
  en: {
    translation: {
      'ОАО «Беларускабель» с декабря 2000 года была внедрена и сертифицирована система': `Since December 2000 JSC "Belaruskabel" 
      has implemented and certified the system quality of development, production and supply of cable products and consumer goods 
      (reinforced cords, molded plugs, extension cords) on the basis of international ISO 9000 series standards.
      <br />
      <br />
      In the period from 01.10.2004 to 01.10.2013, the organization was certified for compliance with the requirements of the international 
      standard ISO 9001 “Quality management systems. Requirements" KEMA Quality B.V. Certificate number        
      No. 2040221-QUA. During the period from 10/02/2013 to 10/01/2022, the management system was certified for compliance 
      with the requirements of the international standard ISO 9001 DEKRA Certification GmbH. Germany (certificate no.320513050).
      <br />
      <br />
      In September 2022 (due to DEKRA’s refusal to work with Russian and Belarusian partners),
       the organization successfully passed certification for compliance with the requirements of the international standard ISO 9001:2015 
       “Quality management systems. Requirements". Certificate for QMS No. BY229591 from 09/26/2022 to 09/26/2025 Bureau Veritas 
       Certification Holding SAS – UK Branch issued by the certification body Foreign Limited Liability Company “Bureau Veritas Bel Ltd”.
      <br />
      <br />     
      Since 2017, the organization has successfully passed certification for compliance with the requirements of the standard 
      STB ISO 9001-2015 “Quality management systems. Requirements" in the National Conformity Confirmation System of the Republic of Belarus. 
      Certificate for QMS No. BY/112 05.01. 012.02 00052 is valid from 10/13/2023 to 10/12/2026 and issued by the Management 
      Systems Certification Body Republican Unitary Enterprise "Kalinkovichi Center for Standardization, Metrology and Certification" (RUE "Kalinkovichi CSMS")
      `,
      'В организации действует сертифицированная система управления окружающей средой согласно требованиям СТБ ISO 14001-2017': 
      `The organization has a certified environmental management system in accordance with the requirements of STB ISO 14001-2017 
      “Environmental Management Systems. Requirements". Certificate No. BY/112 06.01.021 00414 is valid from 03.09.2018 to 03.09.2021 
      and issued by the Certification Body for Environmental Management of the branch of BNTU "Research Polytechnic institute."`,
      'На ОАО «Беларускабель» дислоцируется Техническая приемка №43 АО «РТ-Техприемка»': `JSC "Belaruskabel" is a member of the Elektrokabel Association, 
      that have signed the Quality Declaration adopted at the General Meeting on April 16, 2008.
              <br />
              <br />`,
      'В организации действует сертифицированная система управления охраной труда при производстве кабелей': 
      `The organization has a certified system of health and safety management for production of cables, wires, 
      cords and consumer goods according to the requirements of STB ISO 45001-2020 "Health and safety management systems in professional activities". 
      Certificate No. BY/112 05.04.021.03 00017 was issued by the Certification Authority for management systems "POLYTECH-CERT" and is valid from 
      08/09/2021 to 08/08/2024.`,
    },
  },
  bel: {
    translation: {
      'ОАО «Беларускабель» с декабря 2000 года была внедрена и сертифицирована система': `ААТ "Беларускабель" са снежня 2000 года была ўкаранёна і сертыфікавана сістэма
              якасці распрацоўкі, вытворчасці і пастаўкі кабельнай прадукцыі і спажывецкіх
              тавараў (шнуроў армаваных, відэльцаў апрасаваных, падаўжальнікаў) на базе міжнародных
              стандартаў серыі ІСО 9000. <br />
              <br /> У перыяд з 01.10.2004 па 01.10.2013 арганізацыя была сертыфікавана на адпаведнасць
              патрабаванням міжнароднага стандарту ISO 9001 «Сістэмы менеджменту якасці. Патрабаванні» KEMA
              Quality B.V. нумар сертыфіката № 2040221-QUA. У перыяд з 02.10.2013 па 01.10.2022 сістэма менеджменту
              была сертыфікавана на адпаведнасць патрабаванням міжнароднага стандарту ISO 9001 DEKRA
              Certification GmbH. Германія (сертыфікат № 320513050).
              <br />
              <br />
              У верасні 2022 года (у сувязі з адмовай DEKRA працаваць з расійскімі і беларускімі партнёрамі)
              арганізацыя паспяхова прайшла сертыфікацыю на адпаведнасць патрабаванням міжнароднага стандарту
              ISO 9001:2015 “Сістэмы менеджменту якасці. Патрабаванні». Сертыфікат на СМЯ №BY229591 з 26.09.2022
              па 26.09.2025 Bureau Veritas Certification Holding SAS - UK Branch выдадзены органам па сертыфікацыі
              Замежнае таварыства з абмежаванай адказнасцю "Бюро Верытас Бел Лтд".
              <br />
              <br /> З 2017 года арганізацыя паспяхова прайшла сертыфікацыю на адпаведнасць патрабаванням
              стандарта СТБ ISO 9001-2015 «Сістэмы менеджменту якасці. Патрабаванні» ў Нацыянальнай сістэме
              пацверджання адпаведнасці Рэспублікі Беларусь. Сертыфікат на СМЯ №BY/112 05.01. 012.02 00052
              з 13.10.2023 па 12.10.2026. Выдадзены органам па сертыфікацыі сістэм менеджменту Рэспубліканскае
              унітарнае прадпрыемства "Калінкавіцкі цэнтр стандартызацыі, метралогіі і сертыфікацыі" (РУП "Калінкавіцкі ЦСМС")`,
      'В организации действует сертифицированная система управления окружающей средой согласно требованиям СТБ ISO 14001-2017': `У арганізацыі дзейнічае сертыфікаваная сістэма кіравання навакольным асяроддзем
              згодна з патрабаваннямі СТБ ISO 14001-2017 «Сістэмы кіравання навакольным асяроддзем.
              Патрабаванні». Атрыманы сертыфікат № BY/112 06.01.021 00414 (ад 03.09.2018 да 03.09.2021,
              пярвічная рэгістрацыя з 01.12.2006 года). Выдадзены Органам па сертыфікацыі сістэм
              упраўлення навакольным асяроддзем філіяла БНТУ «Навукова-даследчы політэхнічны
              інстытут». <br />
              <br /> ААТ «Беларускабель» адзіны ў Рэспубліцы Беларусь кабельны завод,
              які прадстаўляе паслугі па тэхнічнай прыёмцы прадукцыі. <br />
              <br />`,
      'На ОАО «Беларускабель» дислоцируется Техническая приемка №43 АО «РТ-Техприемка»': `На ААТ "Беларускабель" дыслакуецца Тэхнічная прыёмка нумар 43 АТ "РТ-Тэхпрыёмка"
              спецыялізаваная арганізацыя Дзяржкарпарацыі "Ростех" (раней АТ "Авіятэхпрыёмка") па
              ацэнцы адпаведнасці прадукцыі ў форме кантролю якасці і тэхнічнай прыёмцы,
              згодна з Палажэннем ад 22.10.2007 «Аб парадку правядзення кантролю якасці матэрыялаў і
              паўфабрыкатаў, якія выкарыстоўваюцца ў вытворчасці ўзбраення, ваеннай, авіяцыйнай і
              спецыяльнай тэхнікі». <br />
              <br /> ААТ «Беларускабель» уваходзіць у склад арганізацый Асацыяцыі «Электракабель»,
              якія падпісалі Дэкларацыю якасці, прынятую на агульным сходзе 16 красавіка 2008.
              <br />
              <br />`,
      'В организации действует сертифицированная система управления охраной труда при производстве кабелей': `У арганізацыі дзейнічае сертыфікаваная сістэма кіравання аховай працы пры
               вытворчасці кабеляў, правадоў, шнуроў, тавараў народнага спажывання згодна
               патрабаванням СТБ ISO 45001-2020 «Сістэма менеджменту здароўя і бяспекі пры
               прафесійнай дзейнасці». <br />
               <br />
               Атрыманы сертыфікат № BY/112 05.04.021.03 00017 (ад 09.08.2021 да 08.08.2024).
               <br />
               <br />
               Выдадзены органам па сертыфікацыі прадукцыі, паслуг, сістэм кіравання "ПАЛІТЭХ-СЕРТ".`,
    },
  },
}
