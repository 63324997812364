import {https} from "../../../config/index.js";

export const getAllProducts = async () => {
    try {
        const response = await https.get('/products/all');
        return response.data?.data || [];
    } catch (e) {
        console.error(e);
    }
};

export const updateProduct = async (payload) => {
    try {
        const response = await https.put(`/products/update`, payload);
        return response;
    } catch (e) {
        console.error(e);
    }
};
