import './QualityPolicy.css'
import {Breadcrumb} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import scrollIntoView from 'scroll-into-view'
import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

export default function QualityPolicy() {
  const {t} = useTranslation()

  useEffect(() => {
    scrollIntoView(document.body.querySelector('#root'), {
      time: 0,
      align: {
        top: 0.1,
      },
    })
  }, [])

  return (
    <>
      <div className="quality-page-container">
        <Breadcrumb className="custom-breadcrumb" style={{marginTop: '38px'}}>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">{t('Меню Главная')}</Link>
          </span>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">{t('Меню Компания')}</Link>
          </span>
          <Breadcrumb.Item active>{t('Менеджмент качества')}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="commonLabels">{t('Менеджмент качества')}</div>
        <div className="quality-page-info">
          <div className="quality-page-info__wrapper">
            <p
              className="quality-page-info__text"
              dangerouslySetInnerHTML={{
                __html: `${t(
                  'ОАО «Беларускабель» с декабря 2000 года была внедрена и сертифицирована система'
                )}`,
              }}
            ></p>
            <div className="quality-page-info__image">
              <img src="/c1.png" alt="c1" />
            </div>
          </div>
          <div className="quality-page-info__wrapper reverse">
            <p
              className="quality-page-info__text"
              dangerouslySetInnerHTML={{
                __html: `${t(
                  'В организации действует сертифицированная система управления окружающей средой согласно требованиям СТБ ISO 14001-2017'
                )}`,
              }}
            ></p>
            <div className="quality-page-info__image reverse">
              <img src="/c2.png" alt="c2" />
            </div>
          </div>
          <div className="quality-page-info__wrapper">
            <p
              className="quality-page-info__text"
              dangerouslySetInnerHTML={{
                __html: `${t(
                  'На ОАО «Беларускабель» дислоцируется Техническая приемка №43 АО «РТ-Техприемка»'
                )}`,
              }}
            ></p>
            <div className="quality-page-info__image">
              <img src="/qualityPolicy/certificate-csms.webp" alt="certificate-image" />
            </div>
          </div>
          <div className="quality-page-info__wrapper reverse">
            <p
              className="quality-page-info__text"
              dangerouslySetInnerHTML={{
                __html: `${t(
                  'В организации действует сертифицированная система управления охраной труда при производстве кабелей'
                )}`,
              }}
            ></p>
            <div className="quality-page-info__image reverse">
              <img src="/c4.png" alt="c4" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
